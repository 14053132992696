export enum GOOD_TYPE_ENUM {
  SPU = 'spu',
  SKC = 'skc',
  // 支持跨境商品库，电商商品库，抖音商品库
  DEFAULT = 'default',
}

// 渠道类型
export enum CHANNEL_ID_ENUM{
  /* 跨境商品 houseofcb */
  KUA_JING_HOUSEOFCB = '1',
  /* 跨境商品 misscircle */
  KUA_JING_MISSCIRCLE = '2',
  /* 跨境商品 ohpolly */
  KUA_JING_OHPOLLY = '3',
  /* 电商商品 淘宝 */
  DIAN_SHANG = '4',
  /* 抖音 */
  DOU_YIN = '5',
}
