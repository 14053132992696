export enum QUERY_SEARCH_TYPE {
  /* 裙型 */
  SKIRT_TYPE = '0',
  /* 设计元素 */
  DESIGN_ELEMENT = '1',
  /* 系列 */
  SERIES = '2',
  /* 设计裁剪 */
  DESIGN_TAILORING = '3',
  /* 店铺 */
  STORE_NAME = '4',
  /* 品牌 */
  BRAND_NAME = '5',
  /* 分类 */
  CATEGORY = '7',
  /* 渠道 */
  CHANNLE_SOURCE = '8',
  /* 风格 */
  STYLE1 = 'style',
}

export enum QUERY_SEARCH_ATTRIBUTE {
  /* 风格 */
  STYLE1 = 'style',
  /* 年份季节 */
  YEAR_SEASON = 'year_season',
  /* 版型 */
  CLOTHING_MODEL = 'clothing_model',
  /* 厚薄 */
  THICKNESS = 'thickness',
  /* 图案 */
  PATTERN = 'pattern',
  /* 面料材质 */
  FABRIC = 'fabric',
}

// 商品状态
export enum GOODS_STATE_ENUM {
  /* 在售 */
  IN_SALE = '1',
  /* 下架 */
  OFF_SALE = '2',
  /* 预售 */
  BOOKING = '3',
}
export const GOODS_STATE_LIST = [
  { label: '在售', value: GOODS_STATE_ENUM.IN_SALE },
  { label: '预售', value: GOODS_STATE_ENUM.BOOKING },
  { label: '下架', value: GOODS_STATE_ENUM.OFF_SALE },
];

// 是否新品
export enum GOODS_IS_NEW_ENUM {
  /* 是 */
  YES = '1',
  /* 否 */
  NO = '0',
}
export const GOODS_IS_NEW_LIST = [
  { label: '是', value: GOODS_IS_NEW_ENUM.YES },
  { label: '否', value: GOODS_IS_NEW_ENUM.NO },
];

// 升降序
export enum SORT_WORD_ENUM {
  /* 上架日期 */
  LATELY_SHELF_DATE = 'latelyShelfDate',
  /* 近7天销量 */
  SALES_NUM_NERA7 = 'salesNumNear7',
  /* 近30天销量 */
  SALES_NUM_NERA30 = 'salesNumNear30',
  /* 总销量 */
  SALES_NUM = 'salesNum',
  /* 总销售额 */
  SALES_AMOUNT = 'salesAmount',
  /* 总收藏数 */
  COLLECT_NUM = 'collectNum',
  /* 总评论数 */
  COMMENT_NUM = 'commentNum'
}
export const SORT_WORD_LIST = [
  { value: SORT_WORD_ENUM.LATELY_SHELF_DATE, label: '上架日期' },
  { value: SORT_WORD_ENUM.SALES_NUM_NERA7, label: '近7天销量' },
  { value: SORT_WORD_ENUM.SALES_NUM_NERA30, label: '近30天销量' },
  { value: SORT_WORD_ENUM.SALES_NUM, label: '总销量' },
  { value: SORT_WORD_ENUM.SALES_AMOUNT, label: '总销售额' },
  { value: SORT_WORD_ENUM.COLLECT_NUM, label: '总收藏数' },
  { value: SORT_WORD_ENUM.COMMENT_NUM, label: '总评论数' },
];
export const HOT_SORT_WORD_LIST = [
  { value: SORT_WORD_ENUM.LATELY_SHELF_DATE, label: '上架日期' },
  { value: SORT_WORD_ENUM.COLLECT_NUM, label: '总收藏数' },
];
export const RECOMMEND_SORT_WORD_LIST = [
  { value: SORT_WORD_ENUM.LATELY_SHELF_DATE, label: '上架日期' },
  { value: SORT_WORD_ENUM.SALES_NUM_NERA7, label: '近7天销量' },
  { value: SORT_WORD_ENUM.COLLECT_NUM, label: '总收藏数' },
];

/**
 * 列表页
 */
// 设计属性
export enum SEARCH_DESIGN_SETTING_ENUM {
  /* 品类 */
  CATEGORY = 'categoryClass',
  /* 属性 */
  ATTRIBUTES = 'categoryAttr',
  /* 风格 */
  STYLE = 'style',
  /* 版型 */
  CLOTHING_MODEL = 'clothingModel',
  /* 面料材质 */
  FABRIC = 'fabric',
  /* 图案 */
  PATTERN = 'pattern',
  /* 厚薄 */
  THICKNESS = 'thickness',
  /* 年份季节 */
  YEAR_SEASON = 'yearSeason',
  /* 适用年龄 */
  FOR_AGE = 'forAge',
}
export const SEARCH_DESIGN_SETTING_LIST = [
  { label: '品类', value: SEARCH_DESIGN_SETTING_ENUM.CATEGORY },
  { label: '属性', value: SEARCH_DESIGN_SETTING_ENUM.ATTRIBUTES },
  { label: '风格', value: SEARCH_DESIGN_SETTING_ENUM.STYLE },
  { label: '版型', value: SEARCH_DESIGN_SETTING_ENUM.CLOTHING_MODEL },
  { label: '面料材质', value: SEARCH_DESIGN_SETTING_ENUM.FABRIC },
  { label: '图案', value: SEARCH_DESIGN_SETTING_ENUM.PATTERN },
  { label: '厚薄', value: SEARCH_DESIGN_SETTING_ENUM.THICKNESS },
  { label: '年份季节', value: SEARCH_DESIGN_SETTING_ENUM.YEAR_SEASON },
  { label: '适用年龄', value: SEARCH_DESIGN_SETTING_ENUM.FOR_AGE },
];

// 基本属性
export enum SEARCH_BASE_SETTING_ENUM{
  /* 渠道名称 */
  CHANNLE_NAME = 'channelName',
  /* 品牌 */
  BRAND_NAME = 'brandName',
  /* 店铺 */
  STORE_NAME = 'storeName',
  /* 商品状态 */
  SPU_STATUS = 'spuStatus',
  /* 是否新品 */
  IS_NEW = 'isNew',
}
export const SEARCH_BASE_SETTING_LIST = [
  { label: '渠道名称', value: SEARCH_BASE_SETTING_ENUM.CHANNLE_NAME },
  { label: '品牌', value: SEARCH_BASE_SETTING_ENUM.BRAND_NAME },
  { label: '店铺', value: SEARCH_BASE_SETTING_ENUM.STORE_NAME },
  { label: '商品状态', value: SEARCH_BASE_SETTING_ENUM.SPU_STATUS },
  { label: '是否新品', value: SEARCH_BASE_SETTING_ENUM.IS_NEW },
];

// 销售属性
export enum SEARCH_SALE_SETTING_ENUM{
  /* 上架日期 */
  SHELF_DATE = 'shelfDate',
  /* 下架日期 */
  OFF_DATE = 'offDate',
  /* 价格 */
  PRICE = 'price',
  /* 近7天销量区间 */
  SALES_NUM_NEAR7 = 'salesNumNear7',
  /* 近30天销量区间 */
  SALES_NUM_NEAR30 = 'salesNumNear30',
  /* 总销量区间 */
  SALES_NUM = 'salesNum',
  /* 总收藏数区间 */
  COLLECT_NUM = 'collectNum',
}
export const SEARCH_SALE_SETTING_LIST = [
  { label: '上架日期', value: SEARCH_SALE_SETTING_ENUM.SHELF_DATE },
  { label: '下架日期', value: SEARCH_SALE_SETTING_ENUM.OFF_DATE },
  { label: '价格', value: SEARCH_SALE_SETTING_ENUM.PRICE },
  { label: '近7天销量', value: SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR7 },
  { label: '近30天销量', value: SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR30 },
  { label: '总销量', value: SEARCH_SALE_SETTING_ENUM.SALES_NUM },
  { label: '总收藏数', value: SEARCH_SALE_SETTING_ENUM.COLLECT_NUM },
];

// TAB
export enum TAB_ENUM {
  /* 全部 */
  DEFAULT = '0',
  /* 热销 */
  HOT = '1',
  /* 推荐 */
  RECOMMED = '2',
}
export const TAB_LIST = [
  { value: TAB_ENUM.DEFAULT, label: '全部' },
  { value: TAB_ENUM.HOT, label: '热销' },
  { value: TAB_ENUM.RECOMMED, label: '推荐' },
];

// 日期选择
export enum HOT_SELLING_DATE_CYCLE_ENUM {
  WEEK = 'week',
  DATE = 'date',
}
export const HOT_SELLING_DATE_CYCLE_LIST = [
  { label: '日', value: HOT_SELLING_DATE_CYCLE_ENUM.DATE },
  { label: '周', value: HOT_SELLING_DATE_CYCLE_ENUM.WEEK },
];

/**
 * 详情页
 */
// 推荐商品 TAB
export enum RECOMMEND_GOOD_TAB_ENUM {
  /* 热销 */
  HOT = '1',
  /* 新品 */
  NEW = '2',
}
export const RECOMMEND_GOOD_TAB_LIST = [
  { value: RECOMMEND_GOOD_TAB_ENUM.HOT, label: '热销' },
  { value: RECOMMEND_GOOD_TAB_ENUM.NEW, label: '新品' },
];

// 趋势分析 TAB
export enum TREND_ANALYSIS_ENUM {
  /* 销量 */
  SALES_VOLUME = '1',
  /* 销售额 */
  SALES = '2',
}
export const TREND_ANALYSIS_LIST = [
  { value: TREND_ANALYSIS_ENUM.SALES_VOLUME, label: '销量' },
  { value: TREND_ANALYSIS_ENUM.SALES, label: '销售额' },
];

// 锚点
export enum ANCHOR_POINT_ENUM {
  /* 商品概况 */
  OVER_VIEW = 'overView',
  /* 数据分布 */
  DATA_DISTRIBUTION = 'dataDistribution',
  /* 推荐商品 */
  RECOMMEND_GOODS = 'recommendGoods',
  /* 趋势分析 */
  TREND_ANALYSIS = 'trendAnalysis',
}
export const ANCHOR_POINT_LIST = [
  { value: ANCHOR_POINT_ENUM.OVER_VIEW, label: '商品概况' },
  { value: ANCHOR_POINT_ENUM.DATA_DISTRIBUTION, label: '数据分布' },
  { value: ANCHOR_POINT_ENUM.RECOMMEND_GOODS, label: '推荐商品' },
  { value: ANCHOR_POINT_ENUM.TREND_ANALYSIS, label: '趋势分析' },
];
