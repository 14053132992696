
import { defineComponent } from 'vue';

import { ANCHOR_POINT_LIST, ANCHOR_POINT_ENUM } from '@/modules/resource-manage/domestic-goods/constant';

export default defineComponent({
  props: {
    current: {
      type: String,
      default: ANCHOR_POINT_ENUM.OVER_VIEW,
    },
  },

  emits: ['select'],

  setup(props, { emit }) {
    const handleClick = (v: ANCHOR_POINT_ENUM) => {
      if (v === props.current) return;
      emit('select', v);
    };

    return {
      handleClick,
    };
  },

  render() {
    return (
      <div class="anchor-point">
        {ANCHOR_POINT_LIST.map(item => (
          <div
            key={item.value}
            class={`anchor-point_item ${item.value === this.current ? 'active' : ''}`}
            onClick={() => this.handleClick(item.value)}
          >
            {item.label}
            <div class="anchor-point_dot" />
          </div>
        ))}
      </div>
    );
  },
});

