import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d325246"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "design-attribute" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty = _resolveComponent("empty")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.data,
      border: ""
    }, {
      empty: _withCtx(() => [
        _createVNode(_component_empty, { content: "暂无数据" })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "设计属性",
          prop: "设计属性"
        }),
        _createVNode(_component_el_table_column, {
          label: "当前商品",
          prop: "当前商品"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              style: _normalizeStyle({
              fontWeight: scope.row['当前商品'] === scope.row['店铺日热销']
                || scope.row['当前商品'] === scope.row['店铺周热销'] ? 800 : 400,
              color: scope.row['当前商品'] === scope.row['店铺日热销']
                || scope.row['当前商品'] === scope.row['店铺周热销'] ? '#4D88FF' : '#606166',
            })
            }, _toDisplayString(scope.row['当前商品'] || '--'), 5)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "店铺日热销",
          prop: "店铺日热销"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              style: _normalizeStyle({
              fontWeight: scope.row['店铺日热销'] === scope.row['当前商品'] ? 800 : 400,
              color: scope.row['店铺日热销'] === scope.row['当前商品'] ? '#4D88FF' : '#606166',
            })
            }, _toDisplayString(scope.row['店铺日热销'] || '--'), 5)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "店铺周热销",
          prop: "店铺周热销"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              style: _normalizeStyle({
              fontWeight: scope.row['店铺周热销'] === scope.row['当前商品'] ? 800 : 400,
              color: scope.row['店铺周热销'] === scope.row['当前商品'] ? '#4D88FF' : '#606166',
            })
            }, _toDisplayString(scope.row['店铺周热销'] || '--'), 5)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}