
import { defineComponent, PropType, ref } from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import {
  confirmImage,
  batchCollectStyle,
} from '@/modules/task-manage/design-task/api/index';
import {
  IGetDesignTaskOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  confirmImageBySpu,
} from '@/modules/resource-manage/domestic-goods/api';
import {
  selectImageToTask,
} from '@/modules/resource-manage/search-image/api';
import { useRouter } from 'vue-router';
import {
  SELECT_IMAGE_SOURCE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import { GOOD_TYPE_ENUM, CHANNEL_ID_ENUM } from './constant';

export default defineComponent({
  components: {
  },
  emits: ['update:visible', 'success'],
  props: {
    type: {
      type: String as PropType<SELECT_IMAGE_SOURCE_ENUM>,
      default: SELECT_IMAGE_SOURCE_ENUM.LINK,
    },
    visible: {
      type: Boolean,
      defalut: false,
    },
    urls: {
      type: Array as PropType<string[]>,
      required: true,
      defalut: '',
    },
    options: {
      type: Array as PropType<IGetDesignTaskOptionsRes[]>,
      required: true,
    },
    categoryClass1: {
      type: String,
      default: '',
    },
    goodType: {
      type: String as PropType<GOOD_TYPE_ENUM>,
      default: GOOD_TYPE_ENUM.SKC,
    },
    channelId: {
      type: String as PropType<CHANNEL_ID_ENUM>,
      default: '',
    },

  },
  setup(props, { emit }) {
    const $router = useRouter();
    const refForm = ref<InstanceType<typeof ElForm> | null>(null);
    const formData = ref({
      id: '',
    });
    const handleClose = async () => {
      await refForm.value?.resetFields();
      emit('update:visible', false);
    };
    const handleConfirm = async () => {
      try {
        await refForm.value?.validate();
        const {
          urls,
          categoryClass1,
          channelId,
        } = props;
        if (props.type === SELECT_IMAGE_SOURCE_ENUM.LINK) {
          if (!props.categoryClass1) return;
          if (props.goodType === GOOD_TYPE_ENUM.SPU) {
            await confirmImageBySpu({
              id: formData.value.id,
              selectedImg: urls[0],
              categoryClass1,
            });
          } else if (props.goodType === GOOD_TYPE_ENUM.DEFAULT) {
            await selectImageToTask({
              id: formData.value.id,
              channelId,
              categoryClass: categoryClass1,
              key: urls[0],
            });
          } else {
            await confirmImage({
              id: formData.value.id,
              selectedImg: urls[0],
              categoryClass1,
            });
          }
        } else {
          await batchCollectStyle({
            id: formData.value.id,
            styleIdList: urls,
          });
        }
        localStorage.setItem('quick_design_id', formData.value.id);
        const row = props.options.find(v => v.id === formData.value.id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        emit('success', formData.value.id);
        handleClose();
        if (props.type === SELECT_IMAGE_SOURCE_ENUM.PLUGIN_COLLECT) {
          window.close();
        }
      } catch (e) {
        console.log(e);
      }
    };
    const handleCreateDesignTask = () => {
      $router.push({
        name: 'DesignTaskManageList',
        params: {
          type: 'create',
        },
      });
    };
    return {
      handleCreateDesignTask,
      formData,
      handleClose,
      rules: {
        id: [
          {
            required: true,
            message: '  ',
          },
        ],
      },
      handleConfirm,
      refForm,
    };
  },
  render() {
    return (
      <el-dialog
        title={this.type === SELECT_IMAGE_SOURCE_ENUM.LINK ? '选图' : '采集'}
        modelValue={this.visible}
        onClose={this.handleClose}
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        show-close={false}
        width="480px"
        custom-class="el-dialog-inner-scroll"
        v-slots={{
          footer: () => (
            <el-row type="flex" justify="end">
              <el-button size="small" onClick={this.handleClose}>取消</el-button>
              <el-button
                type="primary"
                size="small"
                onClick={this.handleConfirm}
              >确定</el-button>
            </el-row>
          ),
        }}
      >
        <el-form
          ref="refForm"
          rules={this.rules}
          model={this.formData}
        >
          <el-form-item
            prop="id"
          >
            <el-select
              v-model={this.formData.id}
              filterable
              class="select-full"
              placeholder="请选择想法"
            >
              {this.options.map(v => (
                <el-option
                  value={v.id}
                  label={v.designTaskName}
                  key={v.id}
                />
              ))}
            </el-select>
          </el-form-item>
          <el-row type="flex" class="tip">
            <div>没有你想要添加的想法？</div>
            <div class="link" onClick={this.handleCreateDesignTask}>创建想法</div>
          </el-row>
        </el-form>
      </el-dialog>
    );
  },
});
