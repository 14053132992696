
import { defineComponent, PropType, computed } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { datefuns } from 'cx-utils';

import IconSpeed from '@/assets/images/home/icon_speed.png';

import Tabs from '@/components/tabs/index.vue';
import {
  INSPIRATION_IMG_TYPE_ENUM,
} from '@/modules/task-manage/design-task/constant';
import {
  downloadImageBySpu,
} from '@/modules/resource-manage/domestic-goods/api';
import {
  IGetDomesticGoodsInfoRes,
} from '@/modules/resource-manage/domestic-goods/api/type.d';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Tabs,
  },

  props: {
    currentTab: {
      type: String,
      default: '1',
    },
    info: {
      type: Object as PropType<IGetDomesticGoodsInfoRes | null>,
      default: null,
    },
    canViewAttr: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['tab', 'select-img'],

  setup(props, { emit }) {
    const $router = useRouter();
    const tabList = computed(() => {
      const base = {
        label: '款式信息',
        value: '1',
      };
      return props.canViewAttr ? [
        base,
        {
          label: '设计属性',
          value: '2',
        }] : [base];
    });

    // 切换Tab
    const handleTab = (v: string) => {
      emit('tab', v);
    };

    // 跳转商品外部链接
    const handleGoLink = () => {
      trackingClick({
        eventName: '打开源链接',
        goodId: props.info?.spu,
      });
      window.open(props.info?.spuLink || '');
    };

    // 跳转店铺分析
    const handleGoAnalyse = () => {
      $router.push({
        name: 'InformationManageShopManageDetail',
        query: {
          storeId: props.info?.storeId,
          channelId: props.info?.channelId,
        },
      });
    };

    // 选图
    const handleSelectImgItem = (row: IGetDomesticGoodsInfoRes | null, type: 'quick'|'normal' = 'normal') => {
      if (!row) return;
      emit(
        'select-img',
        `0/-${row.spu}/-${row.channelId}/-${INSPIRATION_IMG_TYPE_ENUM.STYLE}`,
        row.categoryClass2Name || row.categoryClass1Name,
        type,
      );
    };

    // 下载图片
    const handleDownload = async () => {
      try {
        await downloadImageBySpu({
          spu: props.info?.spu || '',
          filename: `${props.info?.spuName}-${datefuns.formatTime(new Date(), 'YYYY-MM-DD')}.zip`,
        });
      } catch (error) {
        console.log(error);
      }
      trackingClick({
        eventName: '下载图片',
        goodId: props.info?.spu,
      });
    };

    return {
      tabList,
      handleTab,
      handleGoLink,
      handleGoAnalyse,
      handleSelectImgItem,
      handleDownload,
    };
  },

  render() {
    return this.info?.spu && (
      <section class="header-bar">
        <div class="header-bar_info">
          <div class="header-bar_left">
            <div class="header-bar_name" onClick={this.handleGoLink}>{this.info.spuName}</div>
            <div class="header-bar_flex">
              <i class="iconfont icon_shop header-bar_icon" />
              <div class="header-bar_store">{this.info.storeName}</div>
              {this.info.onlineType === 'slow' && <div class="header-bar_analyse" onClick={this.handleGoAnalyse}>
                查看店铺分析
              </div>}
            </div>
          </div>
          <div class="header-bar_category">
            品类：
            {
              [
                this.info.categoryClass1Name,
                this.info.categoryClass2Name,
                this.info.categoryClass3Name,
              ].filter(v => v !== '').join('/')
            }
          </div>
          <div class="header-bar_select">
            <div class="header-bar_select_txt" onClick={() => this.handleSelectImgItem(this.info)}>选图</div>
            <el-tooltip content="添加到最近一次选图分组" placement="bottom-start">
              <div
                class="header-bar_select_quick"
                onClick={() => this.handleSelectImgItem(this.info, 'quick')}
              >
                <img src={IconSpeed} />
              </div>
            </el-tooltip>
          </div>
          <div class="header-bar_down" onClick={this.handleDownload}>下载图片</div>
        </div>
        <div class="header-bar_tab">
          <tabs
            lineHeight={48}
            border={false}
            config={this.tabList}
            modelValue={this.currentTab}
            onChange={this.handleTab}
          />
          <div class="header-bar_update">更新时间：{dayjs(this.info.etlTime).format('YYYY年MM月DD日 HH:mm:ss')}</div>
        </div>
      </section>
    );
  },
});
