
import { defineComponent, ref, watch, PropType } from 'vue';

import TitleBar from '../title-bar/index.vue';
import LineChart from './components/line-chart.vue';

import { ITrendAnalysisItem } from '@/modules/resource-manage/domestic-goods/api/type.d';
import {
  TREND_ANALYSIS_ENUM,
  TREND_ANALYSIS_LIST,
  ANCHOR_POINT_ENUM,
} from '@/modules/resource-manage/domestic-goods/constant';
import { ITrendDataMap } from '../../type.d';

export default defineComponent({
  components: {
    TitleBar,
    LineChart,
  },

  props: {
    trendData: {
      type: Array as PropType<ITrendAnalysisItem[]>,
      default: () => [],
    },
  },

  setup(props) {
    const currentTab = ref(TREND_ANALYSIS_ENUM.SALES_VOLUME);
    const trendState = ref<TREND_ANALYSIS_ENUM>(TREND_ANALYSIS_ENUM.SALES_VOLUME);
    const trendXAxisData = ref<string[]>([]);
    const trendDataMap = ref<ITrendDataMap>({} as ITrendDataMap);
    trendDataMap.value[TREND_ANALYSIS_ENUM.SALES_VOLUME] = {
      seriesData: [],
      yAxisName: '销量',
      legendName: '销量',
    };
    trendDataMap.value[TREND_ANALYSIS_ENUM.SALES] = {
      seriesData: [],
      yAxisName: '销售额（万元）',
      legendName: '销售额（万元）',
    };

    watch(
      () => props.trendData,
      () => {
        trendXAxisData.value = [];
        trendDataMap.value[TREND_ANALYSIS_ENUM.SALES_VOLUME].seriesData = [];
        trendDataMap.value[TREND_ANALYSIS_ENUM.SALES].seriesData = [];
        console.log('props.trendData', props.trendData);
        if (props.trendData.length > 0) {
          props.trendData.forEach((trend) => {
            const { ds, trdSalesSpuNumHp, trdSalesSpuAmtHp } = trend;
            trendXAxisData.value.push(ds);
            trendDataMap.value[TREND_ANALYSIS_ENUM.SALES_VOLUME].seriesData.push(trdSalesSpuNumHp);
            trendDataMap.value[TREND_ANALYSIS_ENUM.SALES].seriesData.push(trdSalesSpuAmtHp);
          });
        }
      },
      { immediate: true },
    );

    const handleTab = (key: TREND_ANALYSIS_ENUM) => {
      currentTab.value = key;
      trendState.value = key;
    };

    return {
      currentTab,
      trendState,
      trendDataMap,
      trendXAxisData,
      handleTab,
    };
  },

  render() {
    return (
      <section id={ANCHOR_POINT_ENUM.TREND_ANALYSIS} class="data">
        <title-bar
          title="趋势分析"
          tabs={TREND_ANALYSIS_LIST}
          modelValue={this.currentTab}
          onChange={this.handleTab}
        />
        <div class="flex">
          <line-chart
            legendName={this.trendDataMap[this.trendState].legendName}
            seriesData={this.trendDataMap[this.trendState].seriesData}
            yAxisName={this.trendDataMap[this.trendState].yAxisName}
            xAxisData={this.trendXAxisData}
          />
        </div>
      </section>
    );
  },
});
