import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import {
  ISelectImageToTaskReq,
  ISearchImageByOssReq,
  ISearchImageByOssRes,
} from './type.d';
import { IEnumOption } from '@/modules/information-manage/shop-manage/api/type.d';

/**
 * 以图搜图
 */
export function searchImageByOss(data:ISearchImageByOssReq) {
  return http.post<ISearchImageByOssRes>({
    url: '/moken-portal/web/v1/search/search-by-oss',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 采集商品图片到设计任务，支持跨境商品库，电商商品库，抖音商品库
 */
export function selectImageToTask(data: ISelectImageToTaskReq) {
  return http.post<IEnumOption[]>({
    url: '/moken-portal/web/v1/design-task/select-product-picture',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}
