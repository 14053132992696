import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { isEmpty } from 'lodash-es';
import { numberfuns } from 'cx-utils';
import { round } from 'number-precision';

import {
  getDomesticDataDistribution,
  getDomesticRecommendGoods,
  getDomesticTrendAnalysis,
} from '@/modules/resource-manage/domestic-goods/api';
import { ITrendAnalysisItem } from '@/modules/resource-manage/domestic-goods/api/type.d';
import { RECOMMEND_GOOD_TAB_ENUM } from '@/modules/resource-manage/domestic-goods/constant';
import { IDistributeData, IRecommendGoodsMap } from '../type.d';

export const useData = () => {
  const $route = useRoute();
  const recommendData = ref<IRecommendGoodsMap>({} as IRecommendGoodsMap);
  recommendData.value[RECOMMEND_GOOD_TAB_ENUM.HOT] = [];
  recommendData.value[RECOMMEND_GOOD_TAB_ENUM.NEW] = [];
  const distributeData = ref<IDistributeData[]>([]);
  const trendData = ref<ITrendAnalysisItem[]>([]);

  // 请求推荐商品
  const fetchRecommendGoods = async () => {
    try {
      const { data } = await getDomesticRecommendGoods({ spu: $route.params.spu as string });
      if (!isEmpty(data)) {
        recommendData.value[RECOMMEND_GOOD_TAB_ENUM.HOT] = data.hotSaleList;
        recommendData.value[RECOMMEND_GOOD_TAB_ENUM.NEW] = data.newProductList;
      }
    } catch (error) {
      console.log(error);
    }
  };
  fetchRecommendGoods();

  // 请求趋势分析
  const fetchTrendAnalysis = async () => {
    try {
      const { data } = await getDomesticTrendAnalysis({ spu: $route.params.spu as string });
      if (!isEmpty(data)) {
        trendData.value = data.map(item => ({
          ...item,
          trdSalesSpuAmtHp: +numberfuns.formatFloat(Number(item.trdSalesSpuAmtHp) / 10000, { pos: 2, round: true }),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  fetchTrendAnalysis();

  const times = (n: string | number) => {
    return round((Number(n) * 100), 2);
  };

  const minus = (n: string | number) => {
    const newN = 100 - Number(n) * 100;
    return round(newN, 2);
  };

  // 请求数据分布
  const fetchDataDistribution = async () => {
    try {
      const { data } = await getDomesticDataDistribution({ spu: $route.params.spu as string });
      if (!isEmpty(data)) {
        distributeData.value = [
          {
            title: '占店铺销量',
            value: times(data.ratioOfStore),
            pie: {
              data: [
                { name: '', value: times(data.ratioOfStore), itemStyle: { color: '#4B85FA' } },
                { name: '', value: minus(data.ratioOfStore), itemStyle: { color: '#E9EFFC' } },
              ],
            },
          },
          {
            title: '占店铺同品类销量',
            value: times(data.ratioOfStoreCategory),
            pie: {
              data: [
                { name: '', value: times(data.ratioOfStore), itemStyle: { color: '#50CAC9' } },
                { name: '', value: minus(data.ratioOfStore), itemStyle: { color: '#E5F4FA' } },
              ],
            },
          },
          {
            title: '占同期上新销量',
            value: times(data.ratioOfNew),
            pie: {
              data: [
                { name: '', value: times(data.ratioOfStore), itemStyle: { color: '#7E60D3' } },
                { name: '', value: minus(data.ratioOfStore), itemStyle: { color: '#ECE7FA' } },
              ],
            },
          },
          {
            title: '占店铺销售额',
            value: times(data.ratioOfStoreAmount),
            pie: {
              data: [
                { name: '', value: times(data.ratioOfStore), itemStyle: { color: '#F99F5B' } },
                { name: '', value: minus(data.ratioOfStore), itemStyle: { color: '#FAF0E8' } },
              ],
            },
          },
          {
            title: '占店铺同品类销售额',
            value: times(data.ratioOfStoreCategoryAmount),
            pie: {
              data: [
                { name: '', value: times(data.ratioOfStore), itemStyle: { color: '#FCC553' } },
                { name: '', value: minus(data.ratioOfStore), itemStyle: { color: '#FDF5E5' } },
              ],
            },
          },
          {
            title: '占同期上新销售额',
            value: times(data.ratioOfNewAmount),
            pie: {
              data: [
                { name: '', value: times(data.ratioOfStore), itemStyle: { color: '#EA5454' } },
                { name: '', value: minus(data.ratioOfStore), itemStyle: { color: '#FAEEEE' } },
              ],
            },
          },
        ];
      }
    } catch (error) {
      console.log(error);
    }
  };
  fetchDataDistribution();

  return {
    distributeData,
    recommendData,
    trendData,
  };
};
