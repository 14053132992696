
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const handleTab = (key: string) => {
      if (key === props.modelValue) return;
      emit('update:modelValue', key);
      emit('change', key);
    };

    return {
      handleTab,
    };
  },

  render() {
    return (
      <div class="title-bar">
        <i class="iconfont icon_icon-tjsp title-bar_icon" />
        <div class="title-bar_title">{this.title}</div>
        <div class="title-bar_tab">
          {this.tabs.map(item => (
            <div
              key={item.value}
              class={`title-bar_tab_item ${item.value === this.modelValue ? 'active' : ''}`}
              onClick={() => this.handleTab(item.value)}
            >{item.label}</div>
          ))}
        </div>
        {this.desc && <div>{this.desc}</div>}
      </div>
    );
  },
});
