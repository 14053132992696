import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { throttle } from 'lodash-es';

import { ANCHOR_POINT_ENUM } from '@/modules/resource-manage/domestic-goods/constant';

export const useAnchor = () => {
  const config = {
    headerHeight: 109, // 59 + 50
  };
  const currentAnchor = ref<ANCHOR_POINT_ENUM>(ANCHOR_POINT_ENUM.OVER_VIEW);
  const wrapEl = ref<HTMLElement|null>(null);

  const handleChangeAnchor = async (v: ANCHOR_POINT_ENUM) => {
    await nextTick();
    const anchorEl = document.getElementById(v);
    if (anchorEl) {
      window.scrollTo({
        top: anchorEl.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const scrollListener = throttle(() => {
    const list = Array.from(wrapEl.value?.children || []);
    list.forEach((item) => {
      const itemRect = item.getBoundingClientRect();
      if (itemRect.y <= config.headerHeight && (itemRect.y + itemRect.height) > config.headerHeight) {
        currentAnchor.value = item.id as ANCHOR_POINT_ENUM;
      }
    });
  }, 100);

  const removeListener = () => {
    window.removeEventListener('scroll', scrollListener);
  };

  onMounted(() => {
    wrapEl.value = document.getElementById('domestic-detail');
    removeListener();
    window.addEventListener('scroll', scrollListener);
  });

  onBeforeUnmount(() => {
    removeListener();
  });

  return {
    currentAnchor,
    handleChangeAnchor,
  };
};
