import { has, hasRoute } from '@/core/plugins/filter';
import { computed } from 'vue';

const usePermissionConfig = () => {
  return {
    /*
    * 商品详情
    * 控制：设计中心-图库-电商商品库-商品详情
    * 跳转路由名称：
    * */
    VIEW_DETAIL: computed(() => has('MOKEN-SJZX-TK-DSSPK-SPXQ')),
    /*
    * 设计属性
    * 控制：设计中心-图库-电商商品库-商品详情-设计属性
    * 跳转路由名称：
    * */
    VIEW_DESIGN_ATTRIBUTE: computed(() => has('MOKEN-SJZX-TK-DSSPK-SJSX')),
    /*
    * 以图搜图
    * 控制：设计中心-以图搜图
    * 跳转路由名称：SearchImageList
    * */
    SEARCH_IMAGE: computed(() => hasRoute('SearchImageList')),
  };
};

export default usePermissionConfig;
