
import { defineComponent, ref, watch } from 'vue';
import { getCommodityLog } from '@/modules/information-manage/shop-manage/api';
import { COMMODITY_STATUS_ENUM } from '@/modules/information-manage/shop-manage/views/detail/constant';
import { datefuns } from 'cx-utils';

export default defineComponent({
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      defalut: false,
    },
    commodityId: {
      type: String,
      default: '',
    },
    storeId: {
      type: String,
      default: '',
    },
    channelId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const commodityLogList = ref<string[]>([]);
    const handleClose = async () => {
      emit('update:visible', false);
    };
    const handleGetCommodityLog = async () => {
      const { data } = await getCommodityLog({
        spu: props.commodityId,
        storeId: props.storeId,
        channelId: props.channelId,
      });
      let firstOnTheShelf = false;
      commodityLogList.value = data.map((log, index) => {
        const { itmShelfSpuDate, spuStatus } = log;
        let status: string = '';
        if (spuStatus === COMMODITY_STATUS_ENUM.ON_THE_SHELF) {
          status = '上架';
          if (!firstOnTheShelf) {
            firstOnTheShelf = true;
            status = '首次上架';
          }
        } else if (spuStatus === COMMODITY_STATUS_ENUM.REMOVED) {
          status = '下架';
        }
        return `${index + 1}、${status} ${datefuns.formatTime(itmShelfSpuDate, 'YYYY-MM-DD')}`;
      });
    };
    watch([
      () => props.commodityId,
      () => props.visible,
    ], () => {
      if (props.visible) {
        handleGetCommodityLog();
      }
    });
    return {
      handleClose,
      commodityLogList,
    };
  },
  render() {
    return (
      <el-dialog
        title="状态日志"
        modelValue={this.visible}
        onClose={this.handleClose}
        center
        show-close={false}
        width="650px"
        custom-class="el-dialog-inner-scroll"
        v-slots={{
          title: () => (
            <el-row type="flex" justify="space-between">
              <div class="el-dialog__title">状态日志</div>
              <div class="clear_all" onClick={this.handleClose}>
                <i class="iconfont icon_icon_close icon"/>
              </div>
            </el-row>
          ),
        }}
      >
        <div class="flex flex-dir-column log-container">
          { this.commodityLogList.length === 0 && <empty content="暂无日志" /> }
          {
            this.commodityLogList.map(commodityLog => (
              <div class="log">{ commodityLog }</div>
            ))
          }
        </div>
      </el-dialog>
    );
  },
});
