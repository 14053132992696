
import { defineComponent, ref, PropType, computed } from 'vue';
import dayjs from 'dayjs';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';

import IconLeftArrow from '@/assets/images/arrow_left.png';
import IconRightArrow from '@/assets/images/arrow_right.png';
import IconView from '@/assets/images/resource/i_view.svg';

import LogDialog from '../log-dialog/index.vue';

import {
  IGetDomesticGoodsInfoRes,
} from '@/modules/resource-manage/domestic-goods/api/type.d';
import {
  GOODS_IS_NEW_LIST,
  GOODS_STATE_LIST,
  ANCHOR_POINT_ENUM,
} from '@/modules/resource-manage/domestic-goods/constant';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    LogDialog,
  },

  props: {
    info: {
      type: Object as PropType<IGetDomesticGoodsInfoRes | null>,
      default: null,
    },
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    canSearchImage: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['search'],

  setup(props, { emit }) {
    const currentIndex = ref(0);
    const swiperEl = ref<any>(null);
    const visibleLog = ref(false);
    const visibleMoreTag = ref(false);

    const tagList = computed(() => {
      return visibleMoreTag.value ? props.tags : props.tags.slice(0, 5);
    });

    const handlePrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };

    const init = (el: any) => {
      swiperEl.value = el;
    };

    const handleItem = (index: number) => {
      if (index !== currentIndex.value) {
        currentIndex.value = index;
      }
    };

    const handleNext = () => {
      if (!props.info) return;
      if (currentIndex.value < props.info.spuPictureLinks.length - 1) {
        currentIndex.value += 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };

    // 查看上下架动态日志
    const handleShowLog = () => {
      visibleLog.value = true;
    };

    // 显示全部标签
    const handleShowAllTag = () => {
      visibleMoreTag.value = true;
    };

    const handleSearch = () => {
      emit('search', props?.info?.spuPictureLinks[currentIndex.value]);
    };

    return {
      init,
      currentIndex,
      visibleLog,
      modules: [Navigation],
      tagList,
      handlePrev,
      handleItem,
      handleNext,
      handleShowLog,
      handleShowAllTag,
      handleSearch,
    };
  },

  render() {
    return this.info?.spu ? (
      <section id={ANCHOR_POINT_ENUM.OVER_VIEW} class="good-info">
        <div class="good-info_title">
          <i class="iconfont icon_icon-spgk good-info_title_icon" />
          <div class="good-info_title_txt">商品概况</div>
        </div>
        <div class="good-info_wrap">
          <div class="cover_layout">
            <custom-image
              hide-on-click-modal={true}
              src={this.info.spuPictureLinks[this.currentIndex]}
              fit="cover"
              class="main_cover"
              preview-src-list={this.info.spuPictureLinks}
              initial-index={this.currentIndex}
            />
            {this.info.spuPictureLinks.length > 0 && <div class="theme_layout">
              <div class={{
                btn: true,
                prev: true,
                disabled: this.currentIndex === 0,
              }} onClick={this.handlePrev}>
                <img src={IconLeftArrow} class="icon" />
              </div>
              <swiper
                slides-per-view={4}
                space-between={12}
                slideToClickedSlide={true}
                grabCursor={true}
                centeredSlides={false}
                onSwiper={this.init}
              >
                {this.info.spuPictureLinks.map((v, i) => (
                  <swiper-slide
                    key={i}
                    class="item"
                    v-slots={{
                      default: () => (
                        <div
                          class={{
                            cover: true,
                            active: this.currentIndex === i,
                          }}
                          onClick={() => this.handleItem(i)}
                        >
                          <img
                            src={v}
                            alt=""
                            class="img"
                          />
                        </div>
                      ),
                    }}
                  />
                ))}
              </swiper>
              <div class={{
                btn: true,
                next: true,
                disabled: this.currentIndex > this.info.spuPictureLinks.length - 1,
              }} onClick={this.handleNext}>
                <img src={IconRightArrow} class="icon" />
              </div>
            </div>}
            {this.info.spuPictureLinks[this.currentIndex]
            && this.canSearchImage && <el-tooltip content="找相似" placement="bottom-end">
              <div onClick={this.handleSearch} class="good-info_search">
                <i class="iconfont icon_zxs good-info_search_icon" />
              </div>
            </el-tooltip>}
          </div>
          <div class="info">
            <div class="info_item">
              <div class="info_title">基本属性</div>
              <div class="info_wrap">
                <div class="item">渠道名称：<text class="desc">{this.info.channelName}</text></div>
                <div class="item">品牌：<text class="desc">{this.info.brandName}</text></div>
                <div class="item">SPU：<text class="desc">{this.info.spu}</text></div>
                {/**
                  <div class="item">
                    商品状态：
                    <text class="desc">
                      {this.$filters.getEnumLabel(GOODS_STATE_LIST, this.info.spuStatus)}
                    </text>
                  </div>
                */}
                <div class="item">
                  是否新品：
                  <text class="desc">{this.$filters.getEnumLabel(GOODS_IS_NEW_LIST, this.info.isNew)}</text>
                </div>
              </div>
            </div>
            <div class="info_item">
              <div class="info_title">销售属性</div>
              <div class="info_wrap">
                <div class="item">
                  价格：
                  <text class="desc">{this.info.price}</text>
                  {this.info.spuHisLowestPriceDt && <el-tooltip
                    effect="dark"
                    content={`
                      ${dayjs(this.info.spuHisLowestPriceDt).format('YYYY年MM月DD日')}
                      达到历史最低价
                      ${this.info.spuHisLowestPrice}元
                    `}
                    placement="top"
                  >
                    <i class="iconfont icon_icon_tt tips-icon" />
                  </el-tooltip>}
                </div>
                <div class="item">
                  日销峰值：
                  <text class="desc">{this.info.trdSalesSpuNumPeak1d}</text>
                  {this.info.trdSalesSpuNumPeakDt && <el-tooltip
                    effect="dark"
                    content={`${dayjs(this.info.trdSalesSpuNumPeakDt).format('YYYY年MM月DD日')}达到日销峰值`}
                    placement="top"
                  >
                    <i class="iconfont icon_icon_tt tips-icon" />
                  </el-tooltip>}
                </div>
                <div class="item">近7天销量：<text class="desc">{this.info.salesNumNear7}</text></div>
                <div class="item">近30天销量：<text class="desc">{this.info.salesNumNear30}</text></div>
                <div class="item">总销量：<text class="desc">{this.info.salesNum}</text></div>
                <div class="item">总收藏数：<text class="desc">{this.info.collectNum}</text></div>
                <div class="item">总评论数：<text class="desc">{this.info.commentNum}</text></div>
                {/**
                  <div class="item">
                    上架日期：
                    <text class="desc">
                      {this.info.latelyShelfDate && dayjs(this.info.latelyShelfDate).format('YYYY年MM月DD日')}
                    </text>
                  </div>
                  <div class="item">
                    下架日期：
                    <text class="desc">
                      {this.info.latelyOffDate && dayjs(this.info.latelyOffDate).format('YYYY年MM月DD日')}
                    </text>
                  </div>
                */}
                {/**
                  {this.info.onlineType === 'slow' && <div class="item btn" onClick={this.handleShowLog}>
                    查看上下架动态
                    <img src={IconView} class="item_icon" />
                  </div>}
                */}
              </div>
            </div>
            <div class="info_item">
              <div class="info_title">描述</div>
              <div class="info_desc">
                {this.info.description}
              </div>
            </div>
            <div class="info_title">评论标签</div>
            <div class="info_desc">
              描述相似度：{ +this.info.descriptionSimilarity > 0 ? this.info.descriptionSimilarity : ''}
            </div>
            <div class="tag">
              {this.tagList.map((item, idx) => (
                <div key={`${idx}`} class="tag_item">{item}</div>
              ))}
              {this.tagList.length < this.tags.length
                  && <div
                class="tag_item more"
                onClick={this.handleShowAllTag}
              >
                +{this.tags.length - this.tagList.length} …
              </div>}
            </div>
          </div>
        </div>
        <log-dialog
          v-model={[this.visibleLog, 'visible']}
          channelId={this.info.channelId}
          storeId={this.info.storeId}
          commodityId={this.info.spu}
        />
      </section>
    ) : null;
  },
});
