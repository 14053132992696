
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

import SelectImgDialog from '@/components/select-img-dialog/index.vue';
import HeaderBar from './components/header-bar/index.vue';
import GoodInfo from './components/good-info/index.vue';
import DesignAttribute from './components/design-attribute/index.vue';
import DataDistribution from './components/data-distribution/index.vue';
import RecommendGoods from './components/recommend-goods/index.vue';
import TrendAnalysis from './components/trend-analysis/index.vue';
import AnchorPoint from './components/anchor-point/index.vue';

import {
  getDesignTaskOptions,
} from '@/modules/task-manage/design-task/api/index';
import {
  IGetDesignTaskOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  getDomesticGoodsDetail,
  confirmImageBySpu,
} from '@/modules/resource-manage/domestic-goods/api';
import {
  IGetDomesticGoodsInfoRes,
} from '@/modules/resource-manage/domestic-goods/api/type.d';
import { GOOD_TYPE_ENUM } from '@/components/select-img-dialog/constant';
import { useData } from './composables/use-data';
import { useAnchor } from './composables/use-anchor';
import usePermissionConfig from '@/modules/resource-manage/domestic-goods/use-permission-config';
import { CHANNEL_ENUM } from '@/constant/global';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    SelectImgDialog,
    HeaderBar,
    GoodInfo,
    DesignAttribute,
    DataDistribution,
    RecommendGoods,
    TrendAnalysis,
    AnchorPoint,
  },

  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const permissionConfig = usePermissionConfig();
    const currentTab = ref('1');
    const info = ref<IGetDomesticGoodsInfoRes|null>(null);
    const selectImgDialogConfig = ref({
      visible: false,
      urls: [] as string[],
      categoryClass1: '',
    });
    const tags = ref<string[]>([]);
    const attributes = ref<{[key: string]: string;}[]>([]); // 设计属性

    const {
      distributeData,
      recommendData,
      trendData,
    } = useData();
    const {
      currentAnchor,
      handleChangeAnchor,
    } = useAnchor();

    // 选图
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    const handleSelectImgItem = async (url: string, category: string, type: 'quick' | 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      let eventDetails: { [key: string]: string; } = {
        eventName: '选图',
        goodId: info.value?.spu || '',
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (type === 'quick' && id) {
        await confirmImageBySpu({
          id,
          selectedImg: url,
          categoryClass1: category,
        });
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgDialogConfig.value.urls = [url];
      selectImgDialogConfig.value.visible = true;
      selectImgDialogConfig.value.categoryClass1 = category;
    };

    // 请求数据
    const getInfo = async () => {
      info.value = {} as IGetDomesticGoodsInfoRes;
      const { data } = await getDomesticGoodsDetail({
        spu: $route.params.spu as string,
      });
      if (data) {
        tags.value = data.commentTag ? JSON.parse(data.commentTag) : [];
        info.value = data;
        const list = data.designAttrJson ? JSON.parse(data.designAttrJson) : [];
        const idx = list.findIndex((item: {[key: string]: string;}) => item['设计属性'] === '品类');
        if (idx > 0) {
          const ary = list.splice(idx, 1);
          list.unshift(ary[0]);
        }
        attributes.value = list;
      } else {
        ElMessage.error('商品不存在');
      }
    };
    getInfo();

    // 切换Tab
    const handleTab = (v: string) => {
      currentTab.value = v;
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: info.value?.spu,
        taskId: id,
      });
    };

    // 跳转电商商品库
    const handleRouteToList = () => {
      $router.push({
        name: 'DomesticGoods',
      });
    };

    // 找相似
    const handleSearch = (url: string) => {
      trackingClick({
        eventName: '找相似',
        goodId: info.value?.spu,
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(url),
          channelId: CHANNEL_ENUM.TAOPIN,
        },
      });
      window.open(newPage.href);
    };

    // 跳转推荐商品
    const handleRecommend = (spu: string) => {
      const newPage = $router.resolve({
        name: 'DomesticGoodsDetail',
        params: {
          spu: encodeURIComponent(spu),
        },
      });
      window.open(newPage.href);
    };

    return {
      permissionConfig,
      GOOD_TYPE_ENUM,
      currentTab,
      info,
      tags,
      selectImgDialogConfig,
      designTaskOptions,
      attributes,
      distributeData,
      recommendData,
      trendData,
      currentAnchor,
      handleChangeAnchor,
      handleTab,
      handleSelectImgItem,
      handleSelectSuccess,
      handleRouteToList,
      handleSearch,
      handleRecommend,
    };
  },
});
