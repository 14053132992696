export enum TAB_ENUM {
  /** 店铺概览 */
  SHOP_OVERVIEW = 'SHOP_OVERVIEW',
  /** 上新分析 */
  NEW_COMMODITY_ANALYSIS = 'NEW_COMMODITY_ANALYSIS',
  /** 热销分析 */
  HOT_SELLING_ANALYSIS = 'HOT_SELLING_ANALYSIS',
  /* 销量飙升分析 */
  SALES_SURGE_ANALYSIS = 'SALES_SURGE_ANALYSIS',
  /* 品类分析 */
  CATEGORY_ANALYSIS = 'CATEGORY_ANALYSIS',
  /* 属性分析 */
  ATTRIBUTE_ANALYSIS = 'ATTRIBUTE_ANALYSIS',
  /* 价格分析 */
  PRICE_ANALYSIS = 'PRICE_ANALYSIS',
}

export const TAB_LIST = [
  { label: '店铺概览', value: TAB_ENUM.SHOP_OVERVIEW },
  { label: '上新分析', value: TAB_ENUM.NEW_COMMODITY_ANALYSIS },
  { label: '热销分析', value: TAB_ENUM.HOT_SELLING_ANALYSIS },
  { label: '销量飙升分析', value: TAB_ENUM.SALES_SURGE_ANALYSIS },
  { label: '品类分析', value: TAB_ENUM.CATEGORY_ANALYSIS },
  { label: '属性分析', value: TAB_ENUM.ATTRIBUTE_ANALYSIS },
  { label: '价格分析', value: TAB_ENUM.PRICE_ANALYSIS },
];

export enum TOP_N_ORDER_ENUM {
  // 全部商品
  ALL = '',
  // 销量
  SALES_VOLUME = 'trdSalesSpuNumTd',
  // 销售额
  VALUE_OF_SALES = 'trdSalesSpuAmtTd',
  // 收藏数
  VALUE_OF_COLLECT = 'trdCllctSpuNumTd',
}

export const TOP_N_ORDER_LIST = [
  { label: '销量', value: TOP_N_ORDER_ENUM.SALES_VOLUME },
  { label: '销售额', value: TOP_N_ORDER_ENUM.VALUE_OF_SALES },
  { label: '收藏数', value: TOP_N_ORDER_ENUM.VALUE_OF_COLLECT },
];

export enum TOP_N_CALCUTE_TYPE_ENUM {
  // 昨天
  YESTERDAY = '0',
  // 累计
  TOTAL = '1'
}

export const TOP_N_CALCUTE_TYPE_LIST = [
  { label: '昨天', value: TOP_N_CALCUTE_TYPE_ENUM.YESTERDAY },
  { label: '累计', value: TOP_N_CALCUTE_TYPE_ENUM.TOTAL },
];

export enum HOT_SELLING_DATE_CYCLE_ENUM {
  WEEK = 'week',
  DATE = 'date',
}

export const HOT_SELLING_DATE_CYCLE_LIST = [
  { label: '日', value: HOT_SELLING_DATE_CYCLE_ENUM.DATE },
  { label: '周', value: HOT_SELLING_DATE_CYCLE_ENUM.WEEK },
];

export enum COMMODITY_STATUS_ENUM {
  ON_THE_SHELF = 1,
  REMOVED = 2,
}

export const COMMODITY_STATUS_LIST = [
  { label: '上架', value: COMMODITY_STATUS_ENUM.ON_THE_SHELF },
  { label: '下架', value: COMMODITY_STATUS_ENUM.REMOVED },
];
