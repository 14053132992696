
import { defineComponent, PropType, computed } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default defineComponent({
  name: 'LineChart',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    xAxisData: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    legendName: {
      type: String,
      default: '',
    },
    yAxisName: {
      type: String,
      default: '',
    },
    seriesData: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  components: {
    VChart,
  },
  setup(props) {
    const option = computed(() => {
      const baseOption = {
        title: {
          text: props.title,
          left: 'center',
        },
        color: ['#647DFA'],
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            return `${params.name}<br>${params.marker}${params.seriesName} ${params.value}`;
          },
        },
        grid: {
          left: '40',
          right: '40',
          bottom: 0,
          containLabel: true,
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [props.legendName],
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.xAxisData,
        },
        yAxis: {
          type: 'value',
          scale: true,
          // name: props.yAxisName,
          nameTextStyle: {
            align: 'left',
            padding: [0, 0, 0, 0],
          },
        },
        series: [
          {
            name: props.legendName,
            data: props.seriesData,
            type: 'line',
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 0.5,
                colorStops: [{
                  offset: 0, color: '#647DFA',
                }, {
                  offset: 1, color: '#ffffff',
                }],
                global: false,
              },
            },
          },
        ],
      };
      return props.options ? Object.assign(baseOption, props.options) : baseOption;
    });
    return {
      option,
    };
  },
  render() {
    return (
      <div class="chart-wrapper">
        {
          this.seriesData.length === 0
            ? <empty content="暂无数据" />
            : <v-chart class="line-chart" option={this.option} autoresize={true} />
        }
      </div>
    );
  },
});
