
import { defineComponent, PropType } from 'vue';

import PieChart from './components/pie-chart.vue';

import { IDistributeData } from '../../type.d';
import { ANCHOR_POINT_ENUM } from '@/modules/resource-manage/domestic-goods/constant';

export default defineComponent({
  components: {
    PieChart,
  },

  props: {
    data: {
      type: Array as PropType<IDistributeData[]>,
      default: () => ([]),
    },
  },

  setup() {

  },

  render() {
    return (
      <section id={ANCHOR_POINT_ENUM.DATA_DISTRIBUTION} class="data">
        <div class="data_title">
          <i class="iconfont icon_icon-sjfb data_title_icon" />
          <div class="data_title_txt">数据分布</div>
          <div>该商品在近30天销量、销售额数据分布概况</div>
        </div>
        {this.data.length === 0 ? <empty content="暂无数据" /> : <div class="data_wrap">
          {this.data.map((item, idx) => (
            <div key={`${idx}`} class="data_item">
              <pie-chart seriesData={item.pie} />
              <div class="data_info">
                <div>{item.title}</div>
                <div class="data_info_value">{item.value}%</div>
              </div>
            </div>
          ))}
        </div>}

      </section>
    );
  },
});
