
import { defineComponent, PropType, computed, ref } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import { LabelLayout } from 'echarts/features';
import { plus } from 'number-precision';

import { IPieData } from '../../../type.d';

use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

export default defineComponent({
  name: 'PieChart',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    legendName: {
      type: String,
      default: '',
    },
    seriesData: {
      type: Object as PropType<IPieData>,
      default: () => ({}),
    },
  },
  components: {
    VChart,
  },
  setup(props) {
    const chartEl = ref<InstanceType<typeof VChart>>();
    const option = computed(() => {
      const {
        seriesData: {
          data,
        },
        legendName,
        title,
      } = props;
      const baseOption = {
        title: {
          text: title,
          left: 'center',
        },
        // tooltip: {
        //   trigger: 'item',
        //   formatter: (params: any) => {
        //     const legend:any = chartEl.value?.getOption().legend;
        //     const keys:string[] = data.map(v => v.name);
        //     Object.entries(legend[0].selected).forEach((v) => {
        //       const [key, selected] = v;
        //       if (!selected) {
        //         const index = keys.findIndex(it => it === key);
        //         keys.splice(index, 1);
        //       }
        //     });
        //     const total = keys.reduce((count, current) => {
        //       return plus(count, data.find(it => it.name === current)!.value);
        //     }, 0);
        //     let percent = 0;
        //     if (total !== 0) {
        //       percent = Math.round((params.value * 10000) / total) / 100;
        //     }
        //     return `${params.data.name}<br/>${tooltipTitle}：${params.value}(${percent}%)`;
        //   },
        // },
        // label: {
        //   formatter: (params: any) => {
        //     const legend:any = chartEl.value?.getOption().legend;
        //     const keys:string[] = data.map(v => v.name);
        //     Object.entries(legend[0].selected).forEach((v) => {
        //       const [key, selected] = v;
        //       if (!selected) {
        //         const index = keys.findIndex(it => it === key);
        //         keys.splice(index, 1);
        //       }
        //     });
        //     const total = keys.reduce((count, current) => {
        //       return plus(count, data.find(it => it.name === current)!.value);
        //     }, 0);
        //     let percent = 0;
        //     if (total !== 0) {
        //       percent = Math.round((params.value * 10000) / total) / 100;
        //     }
        //     return `${params.data.name}：${params.value}(${percent}%)`;
        //   },
        // },
        legend: {
          show: false,
        },
        series: [
          {
            name: legendName,
            data,
            type: 'pie',
            radius: ['50%', '100%'],
            label: {
              show: false,
            },
            silent: true,
          },
        ],
      };
      return props.options ? Object.assign(baseOption, props.options) : baseOption;
    });
    return {
      option,
      chartEl,
    };
  },
  render() {
    return (
      <div class="chart_layout">
        <v-chart ref="chartEl" class="pie-chart" option={this.option} autoresize={true} />
      </div>
    );
  },
});
