
import { defineComponent, ref, PropType } from 'vue';

import TitleBar from '../title-bar/index.vue';

import {
  RECOMMEND_GOOD_TAB_ENUM,
  RECOMMEND_GOOD_TAB_LIST,
  ANCHOR_POINT_ENUM,
} from '@/modules/resource-manage/domestic-goods/constant';
import { IRecommendGoodsItem } from '@/modules/resource-manage/domestic-goods/api/type.d';
import { IRecommendGoodsMap } from '../../type.d';

export default defineComponent({
  components: {
    TitleBar,
  },

  props: {
    data: {
      type: Object as PropType<IRecommendGoodsMap>,
      default: () => {
        return {
          [RECOMMEND_GOOD_TAB_ENUM.HOT]: [],
          [RECOMMEND_GOOD_TAB_ENUM.NEW]: [],
        };
      },
    },
  },

  emits: ['select'],

  setup(props, { emit }) {
    const currentTab = ref(RECOMMEND_GOOD_TAB_ENUM.HOT);

    const handleTab = (key: RECOMMEND_GOOD_TAB_ENUM) => {
      currentTab.value = key;
    };

    const handleSelect = (spu: string) => {
      emit('select', spu);
    };

    return {
      currentTab,
      handleTab,
      handleSelect,
    };
  },

  render() {
    return (
      <section id={ANCHOR_POINT_ENUM.RECOMMEND_GOODS} class="recommend">
        <title-bar
          title="推荐商品"
          desc={this.currentTab === RECOMMEND_GOOD_TAB_ENUM.NEW ? '该店铺最新上架的商品' : '该店铺昨日热销商品'}
          tabs={RECOMMEND_GOOD_TAB_LIST}
          modelValue={this.currentTab}
          onChange={this.handleTab}
        />
        <div class="recommend_container">
          {this.data[this.currentTab].length === 0 ? <empty content="暂无数据" /> : <div class="recommend_wrap">
            {this.data[this.currentTab].map((item: IRecommendGoodsItem) => (
              <div key={item.spu} class="recommend_item" onClick={() => this.handleSelect(item.spu)}>
                <img src={item.spuPictureLink} class="recommend_cover" />
                <el-tooltip content={item.spuName} placement="top-end">
                  <div class="recommend_name">{item.spuName}</div>
                </el-tooltip>
              </div>
            ))}
          </div>}
        </div>
      </section>
    );
  },
});
