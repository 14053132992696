
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Array as PropType<{[key: string]: string;}[]>,
      default: () => [],
    },
  },
});

