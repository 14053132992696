/**
 * 因为后端使用的是dataworks模板来返回数据，所以详情接口也是返回一个列表
 * --分页返回数据模板
    {
      "data": {
        "totalNum": 154,
        "pageSize": 10,
        "rows": [
          {
              "storeId": "2",
              "storeName": "olrain旗舰店",
              "channelType": "国内电商平台",
              "storeType": "天猫旗舰店"
          }
        ],
        "pageNum": 1
      },
      "errCode": 0,
      "requestId": "65254ace16351510208226186e50b0",
      "errMsg": "success",
      "apiLog": null
    }

    --非分页返回数据模板
    {
      "data": [
        {
          "storeId": "2",
          "storeName": "olrain旗舰店",
          "channelType": "国内电商平台",
          "storeType": "天猫旗舰店"
        }
      ],
      "errCode": 0,
      "requestId": "0a0102b016351471137534343e",
      "errMsg": "success",
      "apiLog": null
    }
 */
import http from '@/core/http';
import { useAppStore } from '@/store/app';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import {
  IGetAllShopReq,
  IAllShopPageRes,
  IShopDetailReq,
  IShopDetail,
  ISalesOverviewReq,
  ISalesOverview,
  ISalesTrendReq,
  IShopSalesTrend,
  IUpDownTrendReq,
  IShopUpDownTrend,
  IShopTopSpuReq,
  IShopTopNSpuPageRes,
  INewCommodityOverviewReq,
  INewCommodityDataOverview,
  INewCommodityTrend,
  INewCommodityCategoryAnalysis,
  INewCommodityPriceAnalysis,
  INewCommodityPageReq,
  INewCommodityListsPageRes,
  IHotSellingOverviewReq,
  IHotSellingOverview,
  IHotSellingTrend,
  IHotSellingAnalysisCategoryAnalysis,
  IHotSellingAnalysisPriceAnalysis,
  IHotSellingAnalysisPageReq,
  IHotSellingAnalysisPageRes,
  ICommodityLogReq,
  IEnumOptionReq,
  IEnumOption,
  ICommodityLog,
  ISetFocusShopReq,
  ICancelFocusShopReq,
  ICollectStoreReq,
  IGetHotCategoryRes,
  IGetHotCategoryReq,
  IGetCategoryOverViewDataReq,
  IGetCategoryOverViewDataRes,
  IGetAttributeTrendDataRes,
  IGetAttributeTrendDataReq,
  IGetAttributeDictOptionsRes,
  IGetAttributeDistributionDataRes,
  IGetAttributeDistributionDataReq,
  IGetAttributeOverViewDataRes,
  IGetAttributeOverViewDataReq,
  IGetCategoryDistributionDataReq,
  IGetCategoryDistributionDataRes,
  IGetAttributeDictOptionsReq,
  IStoreSurgeCategoryAnalysisListReq,
  IStoreSurgeCategoryAnalysisListRes,
  IStoreSurgePriceRangeAnalysisListReq,
  IStoreSurgePriceRangeAnalysisListRes,
  IStoreSurgeOverviewDetailReq,
  IStoreSurgeOverviewDetailRes,
  IStoreSurgeSpuPageReq,
  IStoreSurgeSpuPageRes,
  IStorePriceDistributionListReq,
  IStorePriceDistributionListRes,
  IStorePriceTrendListReq,
  IStorePriceTrendListRes,
  IStorePriceDistributionOverviewListReq,
  IStorePriceDistributionOverviewListRes,
} from './type.d';

const server = DOMAIN_SYSTEM_ENUM.mokenDs;
const { quotaAppCode } = useAppStore();
const testPath = '';

/**
 * 获取所有商店分页数据
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98712
 */
export const getAllShopPage = (data: IGetAllShopReq) => {
  return http.post<IAllShopPageRes>({
    url: '/moken-portal/web/v1/store/page',
    loading: true,
    data,
  });
};

/**
 * 查询个人关注店铺
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101666
 */
export const getUserFocusShopPage = (data: IGetAllShopReq) => {
  return http.post<IAllShopPageRes>({
    url: '/moken-portal/web/v1/store-follower/page-person',
    loading: true,
    data,
  });
};
/**
 * 查询团队关注店铺
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101668
 */
export const getGroupFocusShopPage = (data: IGetAllShopReq) => {
  return http.post<IAllShopPageRes>({
    url: '/moken-portal/web/v1/store-follower/page-group',
    loading: true,
    data,
  });
};

/**
 * 统计团队关注店铺数量
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101670
 */
export const getGroupFocusShopCount = () => {
  return http.get<number>({
    url: '/moken-portal/web/v1/store-follower/count-group',
    loading: true,
  });
};
/**
 * 统计个人关注店铺数量
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101960
 */
export const getUserFocusShopCount = () => {
  return http.get<number>({
    url: '/moken-portal/web/v1/store-follower/count-person',
    loading: true,
  });
};

/**
 * 新增店铺收录接口
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101474
 */
export const collectStore = (data: ICollectStoreReq) => {
  return http.post({
    url: '/moken-portal/web/v1/store-collection/save',
    loading: true,
    data,
  });
};

/**
 * 关注店铺
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101664
 */
export const setFocusShop = (data: ISetFocusShopReq) => {
  return http.post({
    url: '/moken-portal/web/v1/store-follower/save',
    loading: true,
    data,
  });
};

/**
 * 取消关注
 * @see https://yapi.ibaibu.com/project/1398/interface/api/101672
 */
export const cancelFocusShop = (data: ICancelFocusShopReq) => {
  return http.post({
    url: '/moken-portal/web/v1/store-follower/cancel',
    loading: true,
    data,
  });
};

/**
 * 获取商店详情
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98708
 */
export const getShopDetail = (params: IShopDetailReq) => {
  return http.get<IShopDetail[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-overview/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 获取销售概况
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98718
 */
export const getSalesOverview = (params: ISalesOverviewReq) => {
  return http.get<ISalesOverview[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-sales/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 获取销售趋势
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98734
 */
export const getSalesTrend = (data: ISalesTrendReq) => {
  return http.post<IShopSalesTrend[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-sales-trend/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取上下架趋势
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98798
 */
export const getShopUpDownTrend = (data: IUpDownTrendReq) => {
  return http.post<IShopUpDownTrend[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-shelf-trend/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取Top100 商品列表
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98774
 */
export const getShopTopSpu = (data: IShopTopSpuReq) => {
  return http.post<IShopTopNSpuPageRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-spu-topn/page`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取首次上新 数据概览
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98750
 */
export const getNewCommodityOverview = (data: INewCommodityOverviewReq) => {
  return http.post<INewCommodityDataOverview[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-first-shelf-overview/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取上新商品数量趋势
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98752
 */
export const getNewCommodityTrend = (data: INewCommodityOverviewReq) => {
  return http.post<INewCommodityTrend[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-first-shelf-trend/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取上新品类分析
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98796
 */
export const getNewCommodityCategoryAnalysis = (data: INewCommodityOverviewReq) => {
  return http.post<INewCommodityCategoryAnalysis[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-first-shelf-category-analysis/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取上新价格带分析
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98754
 */
export const getNewCommodityPriceAnalysis = (data: INewCommodityOverviewReq) => {
  return http.post<INewCommodityPriceAnalysis[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-first-shelf-price/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取上新价格带分析分页
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98754
 */
export const getNewCommodityPage = (data: INewCommodityPageReq) => {
  return http.post<INewCommodityListsPageRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-first-shelf-lists/page`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取日热销数据概览
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98740
 */
export const getHotSellingAnalysisOverview = (data: IHotSellingOverviewReq) => {
  return http.post<IHotSellingOverview[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/hot-products-overview/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取日热销数据趋势
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98742
 */
export const getHotSellingAnalysisTrend = (data: IHotSellingOverviewReq) => {
  return http.post<IHotSellingTrend[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/hot-products-trend/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取日热销品类分析
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98744
 */
export const getHotSellingAnalysisCategoryAnalysis = (data: IHotSellingOverviewReq) => {
  return http.post<IHotSellingAnalysisCategoryAnalysis[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/hot-products-category-analysis/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取日热销价格带分析
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98746
 */
export const getHotSellingAnalysisPriceAnalysis = (data: IHotSellingOverviewReq) => {
  return http.post<IHotSellingAnalysisPriceAnalysis[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/hot-products-price-analysis/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取日热销商品清单
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98748
 */
export const getHotSellingAnalysisPage = (data: IHotSellingAnalysisPageReq) => {
  return http.post<IHotSellingAnalysisPageRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/hot-products/page`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取日志
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98776
 */
export const getCommodityLog = (data: ICommodityLogReq) => {
  return http.post<ICommodityLog[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-topn-shelf/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...data,
    },
    withCredentials: false,
  });
};

/**
 * 获取字典属性值
 * @see https://yapi.ibaibu.com/project/1398/interface/api/98910
 */
export const getEnumOptions = (params: IEnumOptionReq) => {
  return http.get<IEnumOption[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/sys-dict/find-by-dict-type`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 热门品类
 * @see https://yapi.ibaibu.com/project/1398/interface/api/102708
 */
export const getHotCategory = (params: IGetHotCategoryReq) => {
  return http.post<IGetHotCategoryRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-category-distribution-hot/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 品类分布
 * @see https://yapi.ibaibu.com/project/1398/interface/api/103122
 */
export const getCategoryDistributionData = (params: IGetCategoryDistributionDataReq) => {
  return http.post<IGetCategoryDistributionDataRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-category-distribution-doug/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};
/**
 * 品类分布概览
 * @see https://yapi.ibaibu.com/project/1398/interface/api/102674
 */
export const getCategoryOverViewData = (params: IGetCategoryOverViewDataReq) => {
  return http.post<IGetCategoryOverViewDataRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-category-distribution-overview/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 属性值趋势
 * @see https://yapi.ibaibu.com/project/1398/interface/api/102682
 */
export const getAttributeTrendData = (params: IGetAttributeTrendDataReq) => {
  return http.post<IGetAttributeTrendDataRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/attribute-analysis-trend/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};
/**
 * 属性分析字典值
 * @see https://yapi.ibaibu.com/project/1398/interface/api/102790
 */
export const getAttributeDictOptions = (params: IGetAttributeDictOptionsReq) => {
  return http.get<IGetAttributeDictOptionsRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/attribute-analysis-dict/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};
/**
 * 属性值分布
 * @see https://yapi.ibaibu.com/project/1398/interface/api/102686
 */
export const getAttributeDistributionData = (params: IGetAttributeDistributionDataReq) => {
  return http.post<IGetAttributeDistributionDataRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/attribute-distribution/overview`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};
/**
 * 属性值分布
 * @see https://yapi.ibaibu.com/project/1398/interface/api/102686
 */
export const getAttributeOverViewData = (params: IGetAttributeOverViewDataReq) => {
  return http.post<IGetAttributeOverViewDataRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/attribute-distribution-overview/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 飙升数据概览
 * @see https://yapi.ibaibu.com/project/1398/interface/api/106470
 */
export const getSurgeOverviewApi = (params: IStoreSurgeOverviewDetailReq) => {
  return http.get<IStoreSurgeOverviewDetailRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-surge-overview/detail`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 飙升品类分析
 * @see https://yapi.ibaibu.com/project/1398/interface/api/106466
 */
export const getSurgeCategoryAnalysisApi = (params: IStoreSurgeCategoryAnalysisListReq) => {
  return http.post<IStoreSurgeCategoryAnalysisListRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-surge-category-analysis/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};
/**
 * 飙升价格带分析
 * @see https://yapi.ibaibu.com/project/1398/interface/api/106468
 */
export const getSurgePriceAnalysisApi = (params: IStoreSurgePriceRangeAnalysisListReq) => {
  return http.post<IStoreSurgePriceRangeAnalysisListRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-surge-price-range-analysis/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 飙升商品清单
 * @see https://yapi.ibaibu.com/project/1398/interface/api/106472
 */
export const getSurgeSpuApi = (params: IStoreSurgeSpuPageReq) => {
  return http.post<IStoreSurgeSpuPageRes>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-surge-spu/page`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 价格带趋势
 * @see https://yapi.ibaibu.com/project/1398/interface/api/106462
 */
export const getPriceTrendList = (params: IStorePriceTrendListReq) => {
  return http.post<IStorePriceTrendListRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-price-trend/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 价格带分布
 * @see https://yapi.ibaibu.com/project/1398/interface/api/106462
 */
export const getPriceDistributionList = (params: IStorePriceDistributionListReq) => {
  return http.post<IStorePriceDistributionListRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-price-distribution/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};

/**
 * 价格带分布概览
 * @see https://yapi.ibaibu.com/project/1398/interface/api/107616
 */
export const getPriceDistributionOverviewList = (params: IStorePriceDistributionOverviewListReq) => {
  return http.post<IStorePriceDistributionOverviewListRes[]>({
    server,
    url: `/moken-portal/web/v1/intelligence-center${testPath}/store-price-distribution-overview/list`,
    loading: true,
    params: {
      appCode: quotaAppCode,
      ...params,
    },
    withCredentials: false,
  });
};
